//  ATTENTION - THERE ARE TWO ARRAYS
// 1 - AN ARRAY OF TWO OF ARRAYS THAT CONTAIN THAT WHICH IS UNIQUE TO A CURRENCY WITH
//    productIDNum as an index.
// 2 - a second array with what is common to both productIDNum(s)
// an array of both USD and CAD prices
//
// USD first CAD second

const twoUniqAr = [
    [
        {
            productIDNum: '399',
            srp: '39.00',
            multiCurrSugRtl: '39.00',
            fxInputPriceValue:
                'price||b8968a073fce3cab0a868da26940001124f8590ed4bf06d07128ccc672f40866',
            fxTemplateSetValue:
                'template_set||da37d6aca932623a7add7fe1092ae6fcbe919ef6f2ff8fe2278eb74eef8f9235',
        },
        {
            productIDNum: '401',
            srp: '18.00',
            multiCurrSugRtl: '18.00',
            fxInputPriceValue:
                'price||0a586a69fce8d2eb38de1812386dffbd173402b8daefea708d848efd66b7f552',
            fxTemplateSetValue:
                'template_set||63b704c0ba5d5b3e31c4e4a689c46a9088566fa6c273680cdd41cd6960269a50',
        },
        {
            productIDNum: '403',
            srp: '14.00',
            multiCurrSugRtl: '14.00',
            fxInputPriceValue:
                'price||f7bcfda07c6df4047fef641eed1d1c1433dc71160b468f02fe0766bcd2aa9216',
            fxTemplateSetValue:
                'template_set||6e8586c6969bc03432519e440c435441dd0e68112d14479470fb9c1fe91b54a0',
        },
        {
            productIDNum: '404',
            srp: '7.00',
            multiCurrSugRtl: '7.00',
            fxInputPriceValue:
                'price||f97ac554723e8d3fdc1d6ce31196a22e0ed0293f2bf4b33d91e7ff861b7a9cad',
            fxTemplateSetValue:
                'template_set||24658e8543cdf165ce646cb8cb1d146e5f3dec0c3c631c6fc15a8611580cecb0',
        },
        {
            productIDNum: '405',
            srp: '18.00',
            multiCurrSugRtl: '18.00',
            fxInputPriceValue:
                'price||13bfdb3a0a4962da698e780a10c12ecd1bdc6ce48670e074d538f7316b517033',
            fxTemplateSetValue:
                'template_set||5f91a323dc1fc0ef6cf804309c6837d79403f2cf1b540022f27038b65d87d311',
        },
        {
            productIDNum: '406',
            srp: '16.00',
            multiCurrSugRtl: '16.00',
            fxInputPriceValue:
                'price||0840a36ca6dca4473d13fe00c4bf87d39fc9eca61d6e7a1a83b5ce4ce62869ec',
            fxTemplateSetValue:
                'template_set||969da77d3998f95e5dd8bcf0cc035bbab45e49e597dc2b4843e1c5b39b665417',
        },
        {
            productIDNum: '407',
            srp: '10.00',
            multiCurrSugRtl: '10.00',
            fxInputPriceValue:
                'price||9ead8e291fff881e6a69dedb220d2ce9dd78d3d92e82b9a51ce23da92733a566',
            fxTemplateSetValue:
                'template_set||82520b5dad70d4561df9e31049d334860257927582a9a0d9c3beb4278410d123',
        },
        {
            productIDNum: '411',
            srp: '8.00',
            multiCurrSugRtl: '8.00',
            fxInputPriceValue:
                'price||bf99e23bdca12269c99f3e40a53e675171913be9e018a5c52458572603defd56',
            fxTemplateSetValue:
                'template_set||48afe6b75db911d0d35427f02362b6f77a2f9388793134ff1643ec9ad47d4eb5',
        },
        {
            productIDNum: '419',
            srp: '36.00',
            multiCurrSugRtl: '36.00',
            fxInputPriceValue:
                'price||6c2e815dbbd4ffe8d84e0a7f7199fb24953faf5dbcf522331be157ba25db943b',
            fxTemplateSetValue:
                'template_set||c74eee37dc99e318d70529f965daf0a3e7afbf19c327f7baa2743b925ab8d0d4',
        },
        {
            productIDNum: '514',
            srp: '132.00',
            multiCurrSugRtl: '132.00',
            fxInputPriceValue:
                'price||7add8c7794fe5ca53aefb3583e534677b190f7844e5a55d1bdca40e88fede711',
            fxTemplateSetValue:
                'template_set||ee81aa1e63fbdd17e9a3f2df93efb2fb420d1117591af89b835763c2aedb6820',
        },
        {
            productIDNum: '553',
            srp: '21.00',
            multiCurrSugRtl: '21.00',
            fxInputPriceValue:
                'price||6b8029a6e3f36daad25a7b162c990d1381252cdbbaa3d1ef969304bcbe6a8fa3',
            fxTemplateSetValue:
                'template_set||eea2e1f4f5fe69485dfc708608813cbbfde2904885a079f87e3d025e81f36b15',
        },
        {
            productIDNum: '635',
            srp: '18.00',
            multiCurrSugRtl: '18.00',
            fxInputPriceValue:
                'price||b9c541b397efcbcb816f56f2f62d42ed70d61bf9fadb3d32a1ebfbdd6b996f4c',
            fxTemplateSetValue:
                'template_set||7a331c4df7d8b4860148083b6790e57e4e02d012ff242a361d8edda20a90dcfa',
        },
        {
            productIDNum: '645',
            srp: '198.00',
            multiCurrSugRtl: '198.00',
            fxInputPriceValue:
                'price||b305e2fa4aa85a75348503ac799bbcb716f4980b1dcaab9a68de187cc088a0a8',
            fxTemplateSetValue:
                'template_set||40fc00a00200b26d0deb9216e2f2c4085b8f0fd12e314ede980c377d916933fb',
        },
        {
            productIDNum: '646',
            srp: '198.00',
            multiCurrSugRtl: '198.00',
            fxInputPriceValue:
                'price||97620ad0de94fcbcb95b3d72068662db48ebebc0d3647f8beb0e60cb074ea869',
            fxTemplateSetValue:
                'template_set||90fadb84afa5a85b1ace412b4eb2c67db80e15f35a13309c4a11d3350f1e8c73',
        },
        {
            productIDNum: '647',
            srp: '198.00',
            multiCurrSugRtl: '198.00',
            fxInputPriceValue:
                'price||97e5a1dd4e7ccd9137f27c1b41287e61833c51fc85f9172147c872b7592ef33c',
            fxTemplateSetValue:
                'template_set||d4de7fceea876682a51dbb1cc535c2dddecb25b7ef8d11e6a1ebc4fef077a728',
        },
        {
            productIDNum: '648',
            srp: '42.00',
            multiCurrSugRtl: '42.00',
            fxInputPriceValue:
                'price||566f46f608521f238913e2bc88f792044c35dae148a0ceb1c3e75e1d300eb633',
            fxTemplateSetValue:
                'template_set||5f3095feef312850d8f45c8db886206bbe1e9b35a124c280563adcf63ab7a279',
        },
        {
            productIDNum: '99911',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99911',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99912',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99913',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99914',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99915',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
    ],
    [
        {
            productIDNum: '399',
            srp: '54.00',
            multiCurrSugRtl: '54.00CAD',
            fxInputPriceValue:
                'price||018e5c91b28ef1ea5e3a0be45e644ef538535f859edd15effd0920aaa812173b',
            fxTemplateSetValue:
                'template_set||059a18c51306f8d3b2af3743277454b606b5a6ac4ddbeb9beb8c09c9d1a5386b',
        },
        {
            productIDNum: '401',
            srp: '26.00',
            multiCurrSugRtl: '26.00CAD',
            fxInputPriceValue:
                'price||3e78fd0591852644f53a21124dd26f8bac88dcd12fabdd37034cf67f28da6fbb',
            fxTemplateSetValue:
                'template_set||5a5653c1f67b979694fed173d7a53bcb9f0207d7f07fd5431c8ccbe007e696a1',
        },
        {
            productIDNum: '403',
            srp: '21.00',
            multiCurrSugRtl: '21.00CAD',
            fxInputPriceValue:
                'price||280271675a2078587bfccd203aa21fbf4bcd229af5e85d0b170286b146accc81',
            fxTemplateSetValue:
                'template_set||de329653d84c97f39e42d0091aa453911dbb6b3659c8ad51b05f9638c46c5106',
        },
        {
            productIDNum: '404',
            srp: '11.00',
            multiCurrSugRtl: '11.00CAD',
            fxInputPriceValue:
                'price||2d34743b2a6d4ddcec88839f4d299e4307f1fe9160c755fa1e69f804e6db118f',
            fxTemplateSetValue:
                'template_set||1712c50a43298bba98ecacf6ef5cddf752b967175ed9eed865802720034c0ea2',
        },
        {
            productIDNum: '405',
            srp: '25.00',
            multiCurrSugRtl: '25.00CAD',
            fxInputPriceValue:
                'price||8346b4c7bb0eaabbfbb637616fff86ce0ca42c53c7d2105bc8effeb34e01f87b',
            fxTemplateSetValue:
                'template_set||c281f3d6af4bb7e645f3f22b74f3ea5019d472761f90c399a88ef2b801701579',
        },
        {
            productIDNum: '406',
            srp: '20.00',
            multiCurrSugRtl: '20.00CAD',
            fxInputPriceValue:
                'price||fac60a5601df98ca5760a67b909db358bc9c02a9d540fbe7ef226cfe7c455e85',
            fxTemplateSetValue:
                'template_set||1190c92e262d7bd455fad17859f2649575beed786b888f31cdd1e3f6d8283c64',
        },
        {
            productIDNum: '407',
            srp: '15.00',
            multiCurrSugRtl: '15.00CAD',
            fxInputPriceValue:
                'price||007547e170e6a499d4f38d2949d11534f5dbbae441c62d5af300b9e85288430a',
            fxTemplateSetValue:
                'template_set||b65d6db5bffd0a058fc532e3a0ac9fc1d366db1c9a1cbdc40d5c26d372b6112b',
        },
        {
            productIDNum: '411',
            srp: '12.00',
            multiCurrSugRtl: '12.00CAD',
            fxInputPriceValue:
                'price||e64e017d5f616608125513c7f04fb382be8cb06ee87dda3aed340fc40e9b109d',
            fxTemplateSetValue:
                'template_set||4ff3a9c3fd2f6c06003f381be396e0f39f7c50384ca0c53c8c44a0eb35b23c40',
        },
        {
            productIDNum: '419',
            srp: '55.00',
            multiCurrSugRtl: '55.00CAD',
            fxInputPriceValue:
                'price||4c0ec953374ef64cec31f43a988bd49f240ec1ddf6425e34830e01a6fac6bb7f',
            fxTemplateSetValue:
                'template_set||c048f6c7aa3669ef3336493de1da7c62610ea6fc337cca6f5ae6a160e8664182',
        },
        {
            productIDNum: '514',
            srp: '198.00',
            multiCurrSugRtl: '198.00CAD',
            fxInputPriceValue:
                'price||c2ea3a266c138e4b17f95c33654b36aac4c9402e297bf0552b958d50013d7995',
            fxTemplateSetValue:
                'template_set||f274ea51d09c7870668d27e12161289457806a38905bb623f3699ec955c1b3a0',
        },
        {
            productIDNum: '553',
            srp: '25.00',
            multiCurrSugRtl: '25.00CAD',
            fxInputPriceValue:
                'price||616c215010582d3ed04f61c8b73deeb3b5bda31cfe5dbafcba6aaf1985852940',
            fxTemplateSetValue:
                'template_set||1b10ccc4cb57d0e495788b7110223c5b4da3a022bf77c9e66e0a1f434b88fd4b',
        },
        {
            productIDNum: '635',
            srp: '28.00',
            multiCurrSugRtl: '28.00CAD',
            fxInputPriceValue:
                'price||86826feafae66060bd08d456af3f541fa0f2eb0507ced59de2983c4a27a9f96d',
            fxTemplateSetValue:
                'template_set||556f7c83329be68d87c00b6ec456e037c2d15777ca124f2457200a0246cf8e4c',
        },
        {
            productIDNum: '645',
            srp: '308.00',
            multiCurrSugRtl: '308.00CAD',
            fxInputPriceValue:
                'price||5c1cf26dfd4d13de09803546d20239ece54e8d501c08a6925920095cb26054b0',
            fxTemplateSetValue:
                'template_set||1322cefbd5bf59de43c9463a018a000ec4972030fef7755e2c35b75a9697add9',
        },
        {
            productIDNum: '646',
            srp: '308.00',
            multiCurrSugRtl: '308.00CAD',
            fxInputPriceValue:
                'price||da210212b1d481d423b01c1619efab895860b753cc6dd32552326f3a31788790',
            fxTemplateSetValue:
                'template_set||a9aeb80ac23c0c989b69a1b13c1ff4a7aa185c4ccc3eb1d4b75d756d8a9534ec',
        },
        {
            productIDNum: '647',
            srp: '308.00',
            multiCurrSugRtl: '308.00CAD',
            fxInputPriceValue:
                'price||6e2f7eb8a2b9df6dc7c70e48cae22da4c4602a8659178d42ad20535afc7d5ee4',
            fxTemplateSetValue:
                'template_set||e6de2a4e214db5333aad43cbc2ff5227178d2963d320ba0ce3c9757febcc9dd1',
        },
        {
            productIDNum: '648',
            srp: '59.00',
            multiCurrSugRtl: '59.00CAD',
            fxInputPriceValue:
                'price||9c4f634544478e2becdc0ae2645c00827939bb2e016de15ddf59cf2d5050845a',
            fxTemplateSetValue:
                'template_set||a4b4592b5d3386c36fcd0a06035d899332c624acc7937273122ee32b11a9b9fd',
        },

        {
            productIDNum: '99911',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99912',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99913',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99914',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
        {
            productIDNum: '99915',
            srp: '0.00',
            multiCurrSugRtl: '0.00',
            fxInputPriceValue: '',
            fxTemplateSetValue: undefined,
        },
    ],
]

const sharedData = [
    {
        productIDNum: '647',
        productNum: '2',
        productID: 'PBRC1000C2-EB',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000c2-eb',
        displayOrder: 1,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® 1000 ft. Reel (Checkered)',
        description: 'ElectroBraid® - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire.  Its high quality, braided, polyester fiber jacket, meets the high standards established for seat belts & parachute harnesses.',
        fxInputImageValue:
            'image||437593c5df5f5501992fd1a321c83998d912d369aa387c66db0ed547f0dc9ccd',
        fxInputCodeValue:
            'code||6a1db7a9e889f7eb84394b5b110ac32f003e2a66ad73b78ef4e7d295c3c7ebbb',
        fxInputDescriptValue:
            'name||294725ef34a8f003e0568638ed1a4997291be0f8ad072a1a2e7b83871e174547',
        fxInputQtyValue:
            'quantity||dc991ca027a5e284b0afab1f644260cf260526e496beb6027411d4a23224e4f9||open',
    },
    {
        productIDNum: '645',
        productNum: '22',
        productID: 'PBRC1000W2-EB',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000w2-eb',
        displayOrder: 2,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® 1000 ft. Reel (White)',
        description: 'ElectroBraid® - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire. Its high quality, braided, polyester fiber jacket, meets the high standards established for seat belts & parachute harnesses.',
        fxInputImageValue:
            'image||6f79670679f88db111ebc7e108083a75425fa9e7d17ef2f140c6fa314b2a49e9',
        fxInputCodeValue:
            'code||bf565986bf850449390b2ee52ca205581ee912309f147c8d04b7d75251804697',
        fxInputDescriptValue:
            'name||506c4f6cdb9862e383efabee87d6381f3d4a7dd2a133e3ecd24d6c5d99b67964',
        fxInputQtyValue:
            'quantity||02df846e0ec5fea9ec91216f9403cbabd19ba62a0405158d4756228b7c7784a4||open',
    },
    {
        productIDNum: '646',
        productNum: '1',
        productID: 'PBRC1000B2-EB',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000b2-eb',
        displayOrder: 3,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® 1000 ft. Reel (Black)',
        description: 'ElectroBraid® - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire.   Its high quality, braided, polyester fiber jacket, meets the high standards established for seat belts & parachute harnesses.',
        fxInputImageValue:
            'image||8ba69278fa8875147866067592145c50139e51832e42ca73fdcc35175efa6f29',
        fxInputCodeValue:
            'code||00c8bb3991175f45ffe47b507d5acaf01e2fc9dab9f534aae94e6ce9a4bca76b',
        fxInputDescriptValue:
            'name||16406026d5537a9c75000f5f0dfbde94531573f85cd28c270e86f03a1fce1e62',
        fxInputQtyValue:
            'quantity||cd9aca9a71d3c13d5c79c375e1e2fe467ee5514136315fd60e4372be1fbd2acb||open',
    },
    {
        productIDNum: '401',
        productNum: '13',
        productID: 'IROLLB10-EB',
        pkgQty: '10',
        unitLabel: '10/pkg.',
        safe: '6',
        href: 'electrobraid-black-roller-post-insulators-10-pack-irollb10-eb',
        displayOrder: 4,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Roller Post Insulators - Black',
        description:
            'Highly durable ElectroBraid® Roller Insulators. Made with strong, glass filled nylon and UV inhibitor for longer life. Engineered for use on corner, end and gate posts. They eliminate friction when tensioning and can withstand greater forces from vertical changes of direction in the fence line peaks and valleys.',
        featureList:
            'Rugged construction to withstand fence line tension - For use on the outside and inside corners - Fastens with nail, j-bolt, and most other fasteners',
        fxInputImageValue:
            'image||f5d2faaf369d718935e4a68186c5a637491e980df2d29317c2fbe5462a2acc1e',
        fxInputCodeValue:
            'code||f3dec99cf5434b168bca85816d35edf16edd5d4de61d39bc3cc17eccabc9405a',
        fxInputDescriptValue:
            'name||d806785ffa924234d1b53cdcb312b9df533ad3ce26d62c00f02a207bcd0061b4',
        fxInputQtyValue:
            'quantity||1cb018a13e930440983531b3578b784275e61ba4e264fc786437811c0866eec6||open',
    },
    {
        productIDNum: '419',
        productNum: '9',
        productID: 'ASPLTC10-EB',
        pkgQty: '10',
        unitLabel: '10/pkg.',
        safe: '4',
        href: 'electrobraid-copper-split-bolt-connector-aspltc10-eb',
        displayOrder: 5,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Copper Split Bolt Connector',
        description:
            'These top quality electrical connectors are used for joining strands of ElectroBraid® and bridging to the charger and ground rods.',
        featureList:
            'Top quality conductor - Extremely durable - Rugged threading ',
        fxInputImageValue:
            'image||668d2ad40f14ca3f045efac1f28e7cf7accfee1acf172946e8d255bb6b48db1a',
        fxInputCodeValue:
            'code||18e6ef3d1a69818bb146b4ffb05f2455184fc327db2e2c3e0f52059d26f7be7f',
        fxInputDescriptValue:
            'name||4d9f4d82c59b1407bfeefb572402bcee73be8a0d0143d6910d6f3f63817cac02',
        fxInputQtyValue:
            'quantity||eeb0bb014c694eb49e2ddd2e2169c25e6cb28bf5ea0024f9ce49c0ef9a6ffeea||open',
    },
    {
        productIDNum: '399',
        productNum: '12',
        productID: 'ATENSIONKIT-EB',
        pkgQty: '1',
        unitLabel: '1 kit/pkg.',
        safe: '0',
        href: 'electrobraid-tension-kit-atensionkit-eb',
        displayOrder: 6,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Tension Kit',
        description:
            'Needed to tighten your ElectroBraid® fence system. A second tensioning kit is required for paddocks of one acre and larger to properly tighten the fence. An additional kit is recommended when tensioning around multiple corners.',
        featureList:
            'Quickly and easily adds tension to your ElectroBraid® fence - Simple and secure tensioning system - Rugged, sturdy components - Can be reused again and again as needed',
        fxInputImageValue:
            'image||ad54d9f87530ff973ae93faf142e35dd433b2f8456382e677c26012550cdefd9',
        fxInputCodeValue:
            'code||751910cef775442aa1a7510d83fc221fd911aac5c24b0f0f9df7b789aac36144',
        fxInputDescriptValue:
            'name||def21825a0a0f38a745ea03438e13a0f177f44a05462dc867c9988d2346dfd57',
        fxInputQtyValue:
            'quantity||e7515da4d0db3c70b6767427d7d5d89608fd0877e7f55b581cd39e366283d439||open',
    },
    {
        productIDNum: '403',
        productNum: '5',
        productID: 'IWHDCLAWB-EB',
        pkgQty: '20',
        unitLabel: '20/pkg.',
        safe: '12',
        href: 'electrobraid-black-heavy-duty-line-post-insulators-20-pack-iwhdclawb-eb',
        displayOrder: 7,
        onHandQty: 1000,
        descriptionTitle:
            'ElectroBraid® Heavy Duty Line Post Insulators - Black',
        description:
            'ElectroBraid® Heavy-Duty Line Post Insulators are not only for line posts. Use them for brace posts and braces.',
        featureList:
            'Made with high density polyethylene for strength and long life - Use with 1 inch heavy-duty screws to secure line post insulators',
        fxInputImageValue:
            'image||ee18746fe0cd0131871a92d0e1b802ab66fed2eead48fe48d7558cea027a4c10',
        fxInputCodeValue:
            'code||3038f3313bcd2d92ad9aea4883e81e33e2017e3c9e3c846642fc113846a44d85',
        fxInputDescriptValue:
            'name||8be96fe4963c88436ca9e306ca69e9f07baa532102f04ed6856ee7f6d7e27555',
        fxInputQtyValue:
            'quantity||52b4369387458dfe7b42f2b720104e6179fb169a37c79b57f41034a0a7dd1f8e||open',
    },
    {
        productIDNum: '514',
        productNum: '8',
        productID: 'UGCC200-EB',
        pkgQty: '1',
        unitLabel: '200 ft/roll',
        safe: '0',
        href: 'electrobraid-high-voltage-insulated-copper-lead-out-wire-ugcc200-eb',
        displayOrder: 8,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Copper Lead Out Wire 200 ft',
        description: 'Provides conductivity for ElectroBraid® horse fences',
        featureList:
            '14-gauge copper insulated wire withstands up to 15,000 volts - Use with copper split bolts or splicers for non-corroding electrical connections - Designed to be unrolled smoothly from a freely spinning spool - Excellent insulation to protect against voltage leaks',
        fxInputImageValue:
            'image||8e15a7f1098ab8e0ac0eac318e73c79ed65fd5d51b414e85094ed152c3f05118',
        fxInputCodeValue:
            'code||a71a41df52bbaf9ad253b92d3fd6a8bc459e384c30be27d13e2b17e7fd64bdef',
        fxInputDescriptValue:
            'name||40803c614b7d8e8382cb2101e3a9b4a64ba925d93f78b41d1e360fd6b89c983d',
        fxInputQtyValue:
            'quantity||7461a03e844338f45a59792ec9d961dc96933170b29e2288063a83a4910b2908||open',
    },
    {
        productIDNum: '635',
        productNum: '18',
        productID: 'GHWKRW-EB',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-gate-kit-ghwkrw-eb',
        displayOrder: 9,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Gate Kit',
        description:
            'A complete set of components for a 15 to 20 ft electric fence gate.',
        featureList:
            'Easily electrify a gate opening - A cost-effective gate solution',
        fxInputImageValue:
            'image||e21f765525f4432378e8824c0ed30714dd4f35e2c89b1409e6d2af5ee649bf2f',
        fxInputCodeValue:
            'code||4f4be3c9d416ff88eda38cd1cddea0f5f91cfd3a238a3086e391c33793b199d0',
        fxInputDescriptValue:
            'name||718694870c404db06fd920c22d322201e5c6565005c0e89f05565e9a629e84ae',
        fxInputQtyValue:
            'quantity||500b8cb797c583f321b596f89c07118f712eaf485c053f278e547990bd62c4b9||open',
    },
    {
        productIDNum: '404',
        productNum: '6',
        productID: 'AS1-EB',
        pkgQty: '50',
        unitLabel: '50/pkg.',
        safe: '30',
        href: 'electrobraid-heavy-duty-insulator-screw-1-inch-as1-eb',
        displayOrder: 10,
        onHandQty: 0,
        // onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Heavy-Duty Insulator Screw, 1 Inch',
        description:
            '1 inch ElectroBraid® heavy-duty insulator screws provide years of dependable outdoor use.',
        featureList:
            'Heavy-duty construction - Neoprene Bonded Washer - Rust resistant',
        fxInputImageValue:
            'image||0c1592d9296740a04f8aae197d17ae7f7b00897a245943f89b7a36198e1be756',
        fxInputCodeValue:
            'code||24f0d66d3b99dc2fc0df7cea3dc5fbe9f1479122c4ae00f345a5071e4ef890c3',
        fxInputDescriptValue:
            'name||8f6db397244d758a3b5302c00e61ae3fb6aad4767849235df6589de3e118c5ea',
        fxInputQtyValue:
            'quantity||1eeac09fa1ba121961ab4949daef0010193a1c58bf2e16e3e65c83b3d890812c||open',
    },
    {
        productIDNum: '405',
        productNum: '7',
        productID: 'AS25-EB',
        pkgQty: '50',
        unitLabel: '50/pkg.',
        safe: '0',
        href: 'electrobraid-heavy-duty-insulator-screw-2-1-2-inch-as25-eb',
        displayOrder: 11,
        onHandQty: 0,
        // onHandQty: 1000,
        descriptionTitle:
            'ElectroBraid® Heavy-Duty Insulator Screw, 2-1/2 Inch',
        description:
            '2 1/2 inch ElectroBraid® heavy-duty insulator screws provide years of dependable outdoor use.',
        featureList:
            'Heavy-duty construction - Neoprene Bonded Washer - Rust resistant',
        fxInputImageValue:
            'image||64ca6710e6ed09fb8cb301928ff71ade7c14c5b4f307c5857b5549e400d1720d',
        fxInputCodeValue:
            'code||e34c890bc0b44f899ce872a3d895a9e4e01462604e433e96efee3b75a1784a8a',
        fxInputDescriptValue:
            'name||08d5bc5a9cbbd531d43c92a224358afa9770694971254ec51261d9c44b0fe35a',
        fxInputQtyValue:
            'quantity||c418ab651fffd0d7d4b70068ec3b9ea05fddc11256dc952f4ce1ea0dacd0727f||open',
    },
    {
        productIDNum: '406',
        productNum: '24',
        productID: 'AHDCOS-EB',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-cut-off-switch-ahdcos-eb',
        displayOrder: 12,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Cut Off Switch',
        description: 'Disables electric fence at convenient locations',
        featureList:
            'Stainless Steel to help prevent corrosion - Helps to locate short circuits',
        fxInputImageValue:
            'image||57366e2406606f83e397f9e8e1027a6aa26b626ca91a68227fc5c3724507d8f9',
        fxInputCodeValue:
            'code||84ede4260677f92ea56e44241377edc7dfbfb2c113e55555755bf386772119e1',
        fxInputDescriptValue:
            'name||1496cee94d50a22f99dbc80642fe11887514ea7ed61852e09a55cb1a65598441',
        fxInputQtyValue:
            'quantity||b4472144e94446eb00f9f2559899d44df6428e2e8d475457007c6bcba108f035||open',
    },
    {
        productIDNum: '407',
        productNum: '25',
        productID: 'ANPC-EB',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-neutral-plate-connector-anpc-eb',
        displayOrder: 13,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Neutral Plate Connector',
        description:
            'Prevents corrosion between different metals in a wire connection',
        featureList:
            'Allows you to connect ElectroBraid® to steel - galvanized or aluminum wire - Requires the use of a copper lead-out wire',
        fxInputImageValue:
            'image||fb9f0cd2bcea48ac2c9550a7e2f0dcd958ba410d6b3a1cc349b320cb2b86c39f',
        fxInputCodeValue:
            'code||97185aba51124136bf24292d7c3f3428d4db080cf7540432b211ffd649d2c07f',
        fxInputDescriptValue:
            'name||9245d786f37f1765a8c8fe59be85994fe2dfe42b0a47799addc69db9457d1e14',
        fxInputQtyValue:
            'quantity||ba23ead33fbaf546d151ec68449ac6af9370587942604ecc3b693ffa146fedaf||open',
    },
    {
        productIDNum: '411',
        productNum: '26',
        productID: 'WS3-EB',
        pkgQty: '3',
        unitLabel: '3/pkg.',
        safe: '0',
        href: 'electrobraid-warning-sign-ws3-eb',
        displayOrder: 14,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Warning Signs',
        description: 'Alert people to be aware of electric fence',
        featureList: 'Highly visible - Easy to attach',
        fxInputImageValue:
            'image||b090ad8278710a5f16b5a80a76a110da586b1665f82b2da26d184cd8a6e2f602',
        fxInputCodeValue:
            'code||fb31527768ceaacda6c282c0332f1a916c54dc073a911a4be5bff37dff5378f1',
        fxInputDescriptValue:
            'name||f3592d1b06ad3e43f954a4857583598cf098770fb9859abad29c6cb9edf6b7a1',
        fxInputQtyValue:
            'quantity||c871eec62bc774a2128b7085d5dd1440b7fc560dd72745efda1ad0ae91dab8ae||open',
    },
    {
        productIDNum: '553',
        productNum: '23',
        productID: 'CGRC3-EB',
        pkgQty: '3',
        unitLabel: '3/pkg.',
        safe: '0',
        href: 'electrobraid-copper-ground-rod-clamp-cgrc3-eb',
        displayOrder: 15,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid® Copper Ground Rod Clamps (3/pk)',
        description:
            'Clamp maintains a secure connection between the lead out wire and the ground rod',
        featureList:
            'Brass coated to avoid galvanic corrosion with the copper - For use with 5/8″ – 1/2″ ground rods in your electric fence grounding system',
        fxInputImageValue:
            'image||c6826ff256dbd0d655da1155120105cdc1604c6fe9ade077fa6c7f75ca315855',
        fxInputCodeValue:
            'code||a3f61c50f0db27a3ba485d14f2ec8a7700d8167f680a9cd34d9de6e3d70aa280',
        fxInputDescriptValue:
            'name||b6854bfff8522f54926d3f44431df9e789bcf46c3631e57cb86bda23b6dbf8c5',
        fxInputQtyValue:
            'quantity||7b713bc810d1800f988f1b2c9dea9743b42fa328d1e2f1e444360b383ae9f7a2||open',
    },
    {
        productIDNum: '648',
        productNum: '19',
        productID: 'DVM-EB',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-2411-digital-volt-meter',
        displayOrder: 16,
        onHandQty: 1000,
        descriptionTitle: 'Digital Voltmeter',
        description:
            'Get Accurate Read Out up to 9,999 volts. Use the Digital Voltmeter to measure fence line and fence charger voltage.',
        featureList:
            'Extremely sensitive and accurate. Powered by 9-volt battery (included)',
        fxInputImageValue:
            'image||eb52edfb7c212c0bcd187e2a79d9ff699bd458bb67265e964bcd98bea699a5c6',
        fxInputCodeValue:
            'code||30afc473edd27f6427e38a516f386af18c6ed76befe5c0b8fac0c051780d95ce',
        fxInputDescriptValue:
            'name||624d0ff119e278571fad881a8b533e13ff0f4a903bb0d709cf954a266e9cd373',
        fxInputQtyValue:
            'quantity||b77ee255d766929e5f189685de410f3fb6ec182efad6418d587a3e2f77c9119c||open',
    },
    {
        productIDNum: '99911',
        productNum: '99911',
        productID: 'GRSC8-EB',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 91,
        onHandQty: 0,
        descriptionTitle: 'Copper Ground Rods',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99911',
        productNum: '99911',
        productID: 'GRSC8-EB',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 91,
        onHandQty: 0,
        descriptionTitle: 'Copper Ground Rods',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99912',
        productNum: '99912',
        productID: 'ITB-EB',
        pkgQty: '1',
        unitLabel: '',
        safe: '6',
        href: '',
        displayOrder: 92,
        onHandQty: 0,
        descriptionTitle: 'T-Post Insulators',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99913',
        productNum: '99913',
        productID: 'ITCPB-EB',
        pkgQty: '1',
        unitLabel: '',
        safe: '4',
        href: '',
        displayOrder: 93,
        onHandQty: 0,
        descriptionTitle: 'T-Post Cap Insulators',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99914',
        productNum: '99914',
        productID: 'EAC50M-Z',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 94,
        onHandQty: 0,
        descriptionTitle: '50 Mile (2 Joule) AC Low Impedance Energizer',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99915',
        productNum: '99915',
        productID: 'EAC75M-Z',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 95,
        onHandQty: 0,
        descriptionTitle: '75 Mile (4 Joule) AC Low Impedance Energizer',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputQtyValue: '',
    },
]
// code used for uniting the above arrays t present one array with 2 arrays, ( one for each currency), combining the unique with the shared.
const [usdAR, cadAR] = twoUniqAr

let products_usd = []
let products_cad = []

sharedData.forEach((sharedDataItem) => {
    const uniqUSD = usdAR.find(
        (v) => v.productIDNum === sharedDataItem.productIDNum,
    )
    products_usd.push({ ...sharedDataItem, ...uniqUSD })

    const uniqCAD = cadAR.find(
        (v) => v.productIDNum === sharedDataItem.productIDNum,
    )
    products_cad.push({ ...sharedDataItem, ...uniqCAD })
})

export const products = [products_usd, products_cad]
