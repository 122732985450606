import { Typography, Button, Box } from '@mui/material'
import React from 'react'
import { emale } from '../../utilities/appUtilities.js'
// import SendIcon from '@mui/icons-material/Send'

export default function ReturnPolicy() {
    const sx1 = {
        ml: { xs: 0, sm: 2, md: 8 },
        mr: { xs: 1, sm: 5, md: 20 },
        mt: 2,
    }

    const e = emale()
    return (
        <>
            <Typography variant="h4" sx={{ mt: 3 }}>
                Return Policy
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                1. Returns
            </Typography>
            <Typography variant="body1" sx={sx1}>
                We want you to be completely satisfied with your purchase. If
                for any reason you are not, we will gladly accept returns within
                30 days of the delivery date.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                2. Eligibility
            </Typography>
            <Typography variant="body1" sx={sx1}>
                To be eligible for a return, the item must be unused, in the
                same condition that you received it, and in the original
                packaging. Items that have been used, damaged, or are not in
                their original packaging will not be eligible for return.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                3. Return Process
            </Typography>
            <Typography variant="body1" sx={sx1}>
                To initiate a return, please contact our customer service team
                at info@electrobraid.com or call 1 855 EB-FENCE (1 855 323 3623)
                within 30 days of receiving your order. Our team will guide you
                through the return process and provide you with a Return
                Authorization number (RA#) if your return is eligible. All
                product being returned to our warehouse must be authorized and
                reference a RA# on the return packing list. We reserve the right
                to refuse any return that has not been authorized. Please do not
                attempt to make a return without getting an RA# first.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                4. Return Shipping
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Customers are responsible for the cost of return shipping and a
                10% restocking fee unless the return is due to a mistake on our
                part (e.g., wrong item shipped, item arrived damaged). We
                recommend using a trackable shipping service and purchasing
                shipping insurance for your return shipment, as we cannot
                guarantee that we will receive your returned item. When
                applicable, the restocking fee will be applied to all returned
                merchandise.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                5. Refunds
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Once your return is received and inspected, we will send you an
                email to notify you that we have received your returned item. If
                your return is approved, a refund will be processed to your
                original method of payment within 10 business days. Please note
                that it may take some time for the refund to appear on your
                credit card statement, depending on your financial institution.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                6. Damaged or Defective Items
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If you receive a damaged or defective item, please contact our
                customer service team immediately for assistance. We will work
                with you to resolve the issue as quickly as possible.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                7. Contact Us
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If you have any questions about our return policy, please
                contact our customer service team at{' '}
            </Typography>
            <Box
                display={'flex'}
                justifyContent={'flex-start'}
                sx={{ ml: 8, my: 3 }}
            >
                <Typography style={{ fontSize: '1.1rem' }}>{e}</Typography>
            </Box>

            <Typography variant="body1" sx={sx1}>
                1 855 EB-FENCE (1 855 323 3623)
            </Typography>
        </>
    )
}
