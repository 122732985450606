import React from 'react'
import { Typography, Button, Box } from '@mui/material'
// import { Link } from '@mui/material'
import { emale } from '../../utilities/appUtilities.js'
export default function ShipPolicy() {
    const sx1 = {
        ml: { xs: 0, sm: 2, md: 8 },
        mr: { xs: 1, sm: 5, md: 20 },
        mt: 2,
    }

    const e = emale()
    // TODO: 05/18/24 make a icon that allows one to copy into their clipboard our email address
    return (
        <>
            <Typography variant="h4" sx={{ mt: 3 }}>
                Shipping Policy
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                1. Order Processing Time
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Orders are typically processed within 1 business day after
                payment confirmation. Please note that orders are not shipped or
                delivered on weekends or holidays.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                2. Shipping Methods and Costs
            </Typography>
            <Typography variant="body1" sx={sx1}>
                We offer Standard Shipping that typically delivers within 3-5
                business days. You can view the shipping cost for your order
                during the checkout process.
            </Typography>

            {/* <Typography variant="h6" sx={{ mt: 2 }}>
                3. Order Tracking
            </Typography>
            <Typography variant="body1" sx={{    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 }, mt: 2 }}>
                Once your order has been shipped, you will receive a shipping
                confirmation email containing a tracking number. You can use
                this tracking number to track the status of your package on our
                website or the website of the shipping carrier.
            </Typography> */}

            <Typography variant="h6" sx={{ mt: 2 }}>
                3. Shipping Restrictions
                {/* 4. Shipping Restrictions */}
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Please note that we do not ship to P.O. boxes or APO/FPO
                addresses. We are very sorry but at this time we can only ship
                within the USA and the Canadian provinces. Alaska, Hawaii, NWT,
                NU, and YT please call to discuss special shipping arrangements.
            </Typography>

            {/* <Typography variant="h6" sx={{ mt: 2 }}>
                4. International Shipping
                {/* 5. International Shipping */}
            {/* </Typography> */}
            {/* // <Typography variant="body1" sx={{    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 }, mt: 2 }}>
            //     For international orders, please be aware that customs duties,
            //     taxes, and fees may apply. These charges are determined by the
            //     customs agency in the destination country and are the
            //     responsibility of the recipient. We recommend contacting your
            //     local customs office for more information on potential fees.
            // </Typography> */}

            <Typography variant="h6" sx={{ mt: 2 }}>
                4. Delivery Issues
                {/* 6. Delivery Issues */}
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If you experience any issues with the delivery of your order,
                such as delays or lost packages, please contact us at
                info@electrobraid.com or call 1 855 EB-FENCE (1 855 323 3623)
                for assistance. We will work with our shipping carrier to
                resolve the issue and ensure that your order is delivered to you
                as quickly as possible.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                5. Shipping Holidays
                {/* 7. Shipping Holidays */}
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Please note that shipping carriers may have limited or no
                service on certain holidays. Orders placed on or around these
                holidays may experience delays in processing and delivery.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                6. Change of Shipping Address
                {/* 8. Change of Shipping Address */}
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If you need to change the shipping address for your order after
                it has been placed, please contact us at info@electrobraid.com
                or call 1 855 EB-FENCE (1 855 323 3623) as soon as possible. We
                will do our best to accommodate your request, but please note
                that we cannot guarantee that the shipping address can be
                changed once the order has been processed for shipping.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                7. Contact Us
                {/* 9. Contact Us */}
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If you have any questions or concerns about our shipping policy,
                please contact ElectroBraid<sup>&#xAE;</sup> Fence at{' '}
            </Typography>

            <Box
                display={'flex'}
                justifyContent={'flex-start'}
                sx={{ ml: 8, my: 3 }}
            >
                <Typography style={{ fontSize: '1.1rem' }}>{e}</Typography>
            </Box>

            <Typography variant="body1" sx={sx1}>
                or call 1 855 EB-FENCE (1 855 323 3623)
            </Typography>
        </>
    )
}
