import React from 'react'
import { Box, Button, Divider } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import { useMediaQuery } from '@mui/material'
import ToggleCurrency from '../components/toggleCurrency/ToggleCurrency'
import { useShoppingCart } from 'context/ShoppingCartContext'
export default function GotoCart() {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const jc = matches ? 'center' : 'flex-end'
    const { dev_mode } = useShoppingCart()
    return (
        <>
            <Box
                sx={{ mt: 2, mb: 4 }}
                display="flex"
                alignItems="center"
                justifyContent={jc}
            >
                {/* {dev_mode ? (
                    <Box sx={{ mr: 3 }}>
                        <ToggleCurrency />
                    </Box>
                ) : null} */}

                <Box sx={{ mr: 3 }}>
                    <ToggleCurrency />
                </Box>
                <Button
                    component={RouterLink}
                    to="/cart"
                    size="large"
                    // onClick={() => {
                    //     alert('clicked')
                    // }}
                    variant="contained"
                    sx={{ mr: 4, p: 1, fontSize: '1.2rem' }}
                >
                    CART REVIEW
                </Button>
            </Box>
            <Divider orientation="horizontal" variant="top" flexItem />{' '}
        </>
    )
}
