import React from 'react'
import { Button, Box } from '@mui/material'
function convertArrayObjectsToStrings(arr) {
    return arr.map((obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            acc[key] = String(value)
            return acc
        }, {})
    })
}

function createInputParams(data, cart) {
    const cartInString = convertArrayObjectsToStrings(cart)

    return cartInString.map(({ productIDNum: cartItem, quantity }) => {
        const dataItem = data.find(
            ({ productIDNum }) => productIDNum === cartItem,
        )
        return { ...dataItem, quantity }
    })
}

export default function SubmittalForm({ data, cart, isCAD }) {
    const template_set = isCAD ? 'cdn_template_set' : 'DEFAULT'
    // const gstStr = isCAD ? 'GST/HST: 786547281RT0001' : ''
    // const currencySuffix = isCAD ? 'CAD' : ''
    const submittals = createInputParams(data, cart)
    // data =         {
    //     productIDNum: '399',
    //     productID: 'ATENSIONKIT-EB',
    //     descriptionTitle: 'ElectroBraid™  Tension Kit',
    //     srp: '49.49',
    //     fxInputCodeValue:
    //         'code||751910cef775442aa1a7510d83fc221fd911aac5c24b0f0f9df7b789aac36144',
    //     fxInputDescriptValue:
    //         'name||3686e0cbc38bcab063293d3ad40819726b0a07874ccbfbf49aa245ab8648de6f',
    //     fxInputPriceValue:
    //         'price||4a0bbff6896c8997f4c6c3949ccef851149995519520d38a7df08c17e58281dd',
    //     fxInputQtyValue:
    //         'quantity||e7515da4d0db3c70b6767427d7d5d89608fd0877e7f55b581cd39e366283d439||open',
    // }

    const imagePath = `https://www.electrobraid.com/shop/images/cards/`
    const inputGroupingsAr = submittals.map(
        (
            {
                productIDNum,
                productID,
                descriptionTitle,
                multiCurrSugRtl,
                href,
                fxInputCodeValue,
                fxInputDescriptValue,
                fxInputPriceValue,
                fxInputQtyValue,
                fxInputImageValue,
                // fxTemplateSetValue,
                quantity,
            },
            index,
        ) => {
            const prefix = `${index + 2}:`
            // const prefix = index === 0 ? '' : `${index + 1}:`

            return (
                <div key={productIDNum}>
                    <input
                        type="hidden"
                        name={`${prefix}${fxInputCodeValue}`}
                        value={productID}
                    />
                    <input
                        type="hidden"
                        name={`${prefix}${fxInputDescriptValue}`}
                        value={descriptionTitle} //"ElectroBraid®  Copper Split Bolt Connector"
                    />

                    <input
                        type="hidden"
                        name={`${prefix}${fxInputPriceValue}`} //"price||63f1d0a893c8d424fd355d9043fd1720e8185e48a7c40cad68159d4e0cd6e2aa"
                        // name={`${prefix}price`} //"price||63f1d0a893c8d424fd355d9043fd1720e8185e48a7c40cad68159d4e0cd6e2aa"
                        value={multiCurrSugRtl} //"55.99"
                    />
                    <input
                        type="hidden"
                        name={`${prefix}${fxInputQtyValue}`} //"quantity||eeb0bb014c694eb49e2ddd2e2169c25e6cb28bf5ea0024f9ce49c0ef9a6ffeea||open"
                        value={quantity}
                    />
                    <input
                        type="hidden"
                        name={`${prefix}${fxInputImageValue}`}
                        value={`${imagePath}${href}.jpg`}
                    />
                    {/* <input
                        type="hidden"
                        name={`${prefix}${fxTemplateSetValue}`}
                        value={template_set}
                    /> */}
                </div>
            )
        },
    )

    return (
        <Box sx={{ mr: -1 }}>
            <form
                action="https://checkout.electrobraid.com/cart"
                method="post"
                acceptCharset="utf-8"
            >
                {/*  reset all values in the foxy cart */}
                <input type="hidden" name="empty" value="true" />
                <input type="hidden" name="cart" value="checkout" />
                <input type="hidden" name="template_set" value={template_set} />

                {/* <input type="hidden" name="h:gst_str" value={gstStr} /> */}
                {/*  can be referred to as {{ custom_fields.gst_str.value }} in twig  *}

                {/* HMAC values */}
                {inputGroupingsAr}
                <Button
                    sx={{ p: 1.5 }}
                    variant="contained"
                    type="submit"
                    value="Send to Cart"
                    className="submit"
                >
                    Send To Cart
                </Button>
            </form>
        </Box>
    )
}
