import React from 'react'
import { Box, Typography, Stack, Paper, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useShoppingCart } from '../context/ShoppingCartContext'
import { ThemeProvider } from '@mui/system'
import theme from '../styles/theme/'

import { CartItem } from '../components/CartItem'

import {
    intPenniesToStr,
    getLineItemTotal,
    strToIntPennies,
} from '../utilities/money'

import SubmittalForm from '../components/submittalForm'
import Footer from '../components/footer'
import PageFooter from '../components/PageFooter.js'
import ToggleCurrency from '../components/toggleCurrency/ToggleCurrency'

export function ShoppingCart() {
    const { data, cart, emptyCart, dev_mode, isCAD } = useShoppingCart()

    const totalIntPennies = cart.reduce((total, cartItem) => {
        const item = data.find((i) => i.productIDNum === cartItem.productIDNum)
        const unitTotalStr = getLineItemTotal(
            cartItem.quantity,
            item?.srp || '0',
        )
        return total + strToIntPennies(unitTotalStr)
    }, 0)
    const curStr = isCAD ? ' CAD' : ''
    // TODO: 06/06/24 perhaps sort what is in the cart by importance rather than by order of selection
    // function getDisplay(id) {
    //     return data.find(({ productIDNum }) => productIDNum === id).displayOrder
    // }
    // const cartSorted = cart.sort(
    //     (a, b) => getDisplay(a.productIDNum) - getDisplay(b.productIDNum),
    // )

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{ mt: 2 }}>
                    <Stack direction="row">
                        <Paper elevation={9} sx={{ mt: 2 }}>
                            <img src="/shop/images/appbar/logo.png" />
                        </Paper>
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent={'space-between'}
                        // alignContent={'flex-end'}
                        sx={{ mt: 4 }}
                    >
                        <Box alignContent={'baseline'}>
                            <Typography variant="h6" sx={{ ml: 1, pt: 0.5 }}>
                                Cart Review
                            </Typography>
                        </Box>

                        <Stack direction="row" justifyContent={'space-evenly'}>
                            {/* {dev_mode ? (
                                <Box sx={{ mr: { xs: 1, md: 2 }, mb: 2, p: 1 }}>
                                    <ToggleCurrency />
                                </Box>
                            ) : null} */}

                            <Box sx={{ mr: { xs: 1, md: 2 }, mb: 2, p: 1 }}>
                                <ToggleCurrency />
                            </Box>

                            <Button
                                sx={{ mr: { xs: 1, md: 2 }, mb: 2, p: 1 }}
                                variant="contained"
                                onClick={() => {
                                    emptyCart()
                                }}
                            >
                                Empty Items
                            </Button>

                            <Button
                                sx={{ mr: { xs: 1, md: 2 }, mb: 2, p: 1 }}
                                component={RouterLink}
                                to="/"
                                variant="contained"
                            >
                                Return to Shopping
                            </Button>
                        </Stack>
                    </Stack>

                    {cart.map((item) => (
                        <CartItem
                            key={item.productIDNum}
                            productIDNum={item.productIDNum}
                            quantity={item.quantity}
                            storeItems={data}
                        />
                    ))}
                </Box>

                <Stack
                    sx={{ mt: 2 }}
                    direction="row"
                    justifyContent={'flex-end'}
                >
                    <Box
                        // display={flex}
                        // direction="row"
                        alignContent={'center'}
                    >
                        <Stack direction={'row'}>
                            <Typography variant="h6">Total</Typography>
                            <Typography variant="h6" sx={{ mx: 2 }}>
                                {intPenniesToStr(totalIntPennies)}
                                {curStr}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent={'flex-end'}
                    sx={{ mt: 2, mr: 2 }}
                >
                    <SubmittalForm data={data} cart={cart} isCAD={isCAD} />
                </Stack>

                <Footer />
                <PageFooter />
            </ThemeProvider>
        </>
    )
}

// AuthorizeNetStatement
